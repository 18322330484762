
import Vue from "vue";
import {
  billingChangeRequestMapMutations,
  billingChangeRequestMapState
} from "@/store/modules/billingchangerequest";
import * as types from "@/store/mutation-types";
import { useField } from "@/components/FormBuilder/Helpers";
import { capitalizeFirstLetter } from "@/helpers/generalHelpers";
const { __getText } = Vue.prototype;
import { defaultAddressStructure } from "@/helpers/defaultObjects";
export default Vue.extend({
  name: "ChangeRequestInsured",
  props: {
    insuredData: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    changeRequestType: {
      type: String,
      required: true,
      validator: function(value) {
        return (
          value === "PAY_PLAN_REQUEST" ||
          value === "POLICY_CHANGE_REQUEST" ||
          value === "BILLING_REQUEST"
        );
      }
    }
  },
  mounted() {
    if (this.changeRequestType === "BILLING_REQUEST" && this.type === "new") {
      this.editField({
        key: `data.insured.insuredAddress.isPOBoXFormat` as any,
        value: true
      });
    }
  },
  data() {
    return {
      addressSchema: [
        useField(
          {
            type: "radio_button_group",
            key: "data.insured.isInsuredAddressDifferent",
            preamble:
              this.changeRequestType === "BILLING_REQUEST"
                ? "Does the mailing address need to be changed?"
                : "Is the mailing address different?",
            required: true,
            options: [
              { label: "Yes", value: true },
              { label: "No", value: false }
            ]
          },
          ["mb-2"]
        ),
        useField({
          key: "data.insured.insuredAddress",
          label: __getText("fields", "mailingAddressLabel"),
          required: true,
          type: "address",
          addressType: "mailingAddress",
          isPOBOXMailingAddress: "true",
          conditions: {
            and: [
              {
                operator: "equals",
                field: "data.insured.isInsuredAddressDifferent",
                value: true
              }
            ]
          },
          useFormChangedVisibly: true,
          allowSyncingWithPhysicalAddress: false
        })
      ],
      addressFields: [
        "unitNumber",
        "streetName",
        "city",
        "state",
        "zipCode",
        "country",
        "streetDirection",
        "houseNumber",
        "streetType",
        "county"
      ]
    };
  },
  methods: {
    ...billingChangeRequestMapMutations({
      editField: types.SET_EDIT_FIELD,
      setInsuredAddressData: types.SET_INSURED_ADDRESS
    }),
    handleAddressFieldUpdate({ key, value }: { key: any; value: any }) {
      if (key === "data.insured.isInsuredAddressDifferent") {
        if (value) {
          this.setInsuredAddressData({
            isInsuredAddressDifferent: true,
            insuredAddress: {
              ...defaultAddressStructure,
              isSameAsPhysicalAddress: false
            }
          });
        } else {
          this.setInsuredAddressData({
            isInsuredAddressDifferent: false,
            insuredAddress: {
              ...(this.editing?.data.insured.location as any),
              isSameAsPhysicalAddress: null
            }
          });
        }
      } else if (key === "data.insured.insuredAddress") {
        const { fieldKey, value: fieldValue } = value;
        if (fieldKey === "isSameAsPhysicalAddress") {
          if (fieldValue) {
            this.setInsuredAddressData({
              insuredAddress: {
                ...(this.editing?.data.insured.location as any),
                isSameAsPhysicalAddress: fieldValue
              }
            });
          } else {
            this.setInsuredAddressData({
              insuredAddress: {
                ...defaultAddressStructure,
                isSameAsPhysicalAddress: fieldValue
              }
            });
          }
        } else {
          this.editField({
            key: `data.insured.insuredAddress.${fieldKey}` as any,
            value: fieldValue
          });
        }
      } else {
        const { fieldKey, value: fieldValue } = value;
        this.editField({
          key: `data.insured.insuredAddress.${fieldKey}` as any,
          value: fieldValue
        });
      }
    },
    capitalizeFirstLetter(word: string): string {
      return capitalizeFirstLetter(word);
    }
  },
  computed: {
    ...billingChangeRequestMapState(["makingApiRequest", "editing"])
  }
});
